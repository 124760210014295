/*
    Global Styles
*/

/* añadir estilos botones comunes*/

.btnBlack{
    border-radius: 25px !important;
    padding: .5rem 2rem !important;
    pointer-events: initial;
    text-transform: uppercase;
    background: #000 !important;
    color: #fff !important;
}

.btnBlack:hover{
    background: #d4c8b7 !important;
    color: #000 !important;
}

/* Product Shipping dialog address */

.box{
    border: solid 1px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    margin-bottom: 1rem;
}
.mb0{
    margin-bottom: 0;
}
.circlebg{
    background-color: #d4c8b7;
}
.separar{
    margin: 1rem 0;
}
.btnredondo{
    border-radius: 25px;
    padding: .5rem 2rem;
    margin: 1rem 0 1rem 0;
}

.shipping-address{
    border-bottom: 1px solid rgba(0,0,0,0.12); 
    padding: 0 0 1rem 0;
    margin-right: 2rem;
}