/*
    Component Styles - Product Draw Play
*/

.product-info-wrap{
    padding: 0 2rem;
}

.product-info-wrap .product-name{
    font-size: 2.1rem;
    margin-bottom: 1rem;
}

.product-info-wrap .product-description{
    font-size: 0.875rem;
    color: r
    gba(0, 0, 0, 0.6);
}

.product-info-wrap .product-expansion-panel{
    width: 100%;
    border: none;
    border-bottom: 1px solid rgba(0,0,0,0.12);
    background: transparent;
    box-shadow: none;
    margin-top: 2rem;
}

.product-info-wrap .product-expansion-panel:before{
    display: none;
}

.product-info-wrap .product-expansion-panel *{
    border: none;
    box-shadow: none;
}

.product-info-wrap .product-expansion-heading{
    font-size: 0.875rem;
    font-weight: 400;
}

.product-info-wrap .product-expansion-details{
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.6);
}
