/*
    Component Styles - Product Draw  Win
*/

.product-win-wrap{
    padding: 0 2rem;
    text-align: center;
}

.product-win-wrap .product-name{
    font-size: 2.1rem;
}

.product-win-wrap img{
    max-width: 100%;
    display: block;
    margin: auto;
}

.product-win-wrap .product-win-title{
    margin: 1rem;
    color: #01a299;
    font-weight: 500;
}

.product-win-wrap .shipping-button{
    background: #d4c8b7;
    color: #000;
    border-radius: 25px;
    padding: .5rem 2rem;
    pointer-events: initial;
    margin-top: 1rem;
}

.product-win-wrap .shipping-button:hover{
    background: #000;
    color: #fff;
}
