@import 'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap';
@import './Assets/CSS/Butler.css';

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Poppins', sans-serif;
}

.topbar{
  display: flex;
  justify-content: space-between;
}

.topbar .menu-item{
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1.25px;
}

.page-footer{
  padding: 50px 0 100px;
}

.grey{
  color: #9E9E9E !important;
}

.Slide-title-46,
.Slide-subtitle-47{
  color: #222 !important;
}

a{
  color: #000;
  font-size: inherit;
  cursor: pointer;
}

.slick-dots li{
  width: 10px;
}


.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
