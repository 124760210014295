@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
/*
    Global Styles
*/

/* añadir estilos botones comunes*/

.btnBlack{
    border-radius: 25px !important;
    padding: .5rem 2rem !important;
    pointer-events: initial;
    text-transform: uppercase;
    background: #000 !important;
    color: #fff !important;
}

.btnBlack:hover{
    background: #d4c8b7 !important;
    color: #000 !important;
}

/* Product Shipping dialog address */

.box{
    border: solid 1px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    margin-bottom: 1rem;
}
.mb0{
    margin-bottom: 0;
}
.circlebg{
    background-color: #d4c8b7;
}
.separar{
    margin: 1rem 0;
}
.btnredondo{
    border-radius: 25px;
    padding: .5rem 2rem;
    margin: 1rem 0 1rem 0;
}

.shipping-address{
    border-bottom: 1px solid rgba(0,0,0,0.12); 
    padding: 0 0 1rem 0;
    margin-right: 2rem;
}
/*
    Component Styles - Header
*/

.topbar-left{
    flex-basis: 20%;
    display: flex;
    align-items: center;
    text-align: left;
}
@media (min-width: 768px ){ .topbar-left{ flex-basis: 33.33%; }}

.topbar-center{
    flex-basis: 60%;
    align-items: center;
    text-align: center;
}
@media (min-width: 768px ){ .topbar-center{ flex-basis: 33.33%; }}

.topbar-center img{
    max-width: 100%;
    display: block;
    margin: auto;
}

.topbar-right{
    flex-basis: 20%;
    align-items: center;
    text-align: right;
}
@media (min-width: 768px ){ .topbar-right{ flex-basis: 33.33%; }}
/*
    Component Styles - Product Draw Play
*/

.product-info-wrap{
    padding: 0 2rem;
}

.product-info-wrap .product-name{
    font-size: 2.1rem;
    margin-bottom: 1rem;
}

.product-info-wrap .product-description{
    font-size: 0.875rem;
    color: r
    gba(0, 0, 0, 0.6);
}

.product-info-wrap .product-expansion-panel{
    width: 100%;
    border: none;
    border-bottom: 1px solid rgba(0,0,0,0.12);
    background: transparent;
    box-shadow: none;
    margin-top: 2rem;
}

.product-info-wrap .product-expansion-panel:before{
    display: none;
}

.product-info-wrap .product-expansion-panel *{
    border: none;
    box-shadow: none;
}

.product-info-wrap .product-expansion-heading{
    font-size: 0.875rem;
    font-weight: 400;
}

.product-info-wrap .product-expansion-details{
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.6);
}

/*
    Component Styles - Product Betting
*/

.product-betting-title{
    margin-top: 2rem !important;
    text-align: center;
    font-size: 20px !important;
    font-weight: 500 !important;
}

ul.betting{
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    justify-content: flex-start;
    box-sizing: border-box;
    
    max-width: 375px;
    margin: 1rem auto;
}

ul.betting li{
    font-size: 0.9375rem; /*theme.typography.pxToRem(15)*/
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

ul.betting li label{
    position: relative;
    display: flex;
    width: 48px; /* 48/56px*/
    height: 48px; /* 48/56px*/
    border: solid 2px rgba(0, 0, 0, 0.12);
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    margin: 5px;
    font-weight: 500;
    font-size: 1.4rem;
}

/*@media (min-width: 1024px ){
    ul.betting li label{
        width: 56px;
        height: 56px;
    }
}*/

ul.betting li label:hover{
    background-color: rgba(0, 0, 0, 0.12);
    cursor: pointer;
}

ul.betting li label.selected{
    background-color: rgba(0, 0, 0, 0.9);
    color: #FAFAFA; /*theme.palette.secondary.main*/
}

ul.betting li label .check{
    position: absolute;
    left: -5000px;
}

.choosepanel{
    border-bottom: 1px solid rgba(0,0,0,0.12);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
}

.buypanel{
    border-top: 1px solid rgba(0,0,0,0.12);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
}

.buypanel .left, .choosepanel .left{
    display: flex;
    flex-direction: column;
    align-items: left;
}

.buypanel .right, .choosepanel .right{
    display: flex;
    flex-direction: column;
    align-items: right;
}

.choosepanel .choosebutton, .buypanel .choosebutton{
    border-radius: 25px;
    padding: .5rem 2rem;
    pointer-events: initial;
    text-transform: uppercase;
    background: #000;
    color: #fff;
}

.choosepanel .choosebutton:hover, .buypanel .choosebutton:hover{
    background: #d4c8b7;
    color: #000;
}

.buypanel .playbutton{
    border-radius: 25px;
    padding: .5rem 2rem;
    pointer-events: initial;
    text-transform: uppercase;
    background: #01a299;
    color: #fff;
}

.buypanel .playbutton:hover{
    background: #fff;
    color: #01a299;
    border: 1px solid #01a299;
}
/*
    Component Styles - Product Draw  Lose
*/

.product-lose-wrap{
    padding: 0 2rem;
    text-align: center;
}

/*
    Component Styles - Product Draw  Win
*/

.product-win-wrap{
    padding: 0 2rem;
    text-align: center;
}

.product-win-wrap .product-name{
    font-size: 2.1rem;
}

.product-win-wrap img{
    max-width: 100%;
    display: block;
    margin: auto;
}

.product-win-wrap .product-win-title{
    margin: 1rem;
    color: #01a299;
    font-weight: 500;
}

.product-win-wrap .shipping-button{
    background: #d4c8b7;
    color: #000;
    border-radius: 25px;
    padding: .5rem 2rem;
    pointer-events: initial;
    margin-top: 1rem;
}

.product-win-wrap .shipping-button:hover{
    background: #000;
    color: #fff;
}

@font-face {
    font-family: 'Butler';
    src: url(/static/media/butler_ultra_light-webfont.a2766e39.woff2) format('woff2'),
        url(/static/media/butler_ultra_light-webfont.006ba0c2.woff) format('woff'),
        url(/static/media/butler_ultra_light-webfont.c4c9cb6b.ttf) format('truetype'),
        url(/static/media/butler_ultra_light-webfont.47903a19.otf) format('opentype');
    font-weight: normal;
    font-style: normal;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Poppins', sans-serif;
}

.topbar{
  display: flex;
  justify-content: space-between;
}

.topbar .menu-item{
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1.25px;
}

.page-footer{
  padding: 50px 0 100px;
}

.grey{
  color: #9E9E9E !important;
}

.Slide-title-46,
.Slide-subtitle-47{
  color: #222 !important;
}

a{
  color: #000;
  font-size: inherit;
  cursor: pointer;
}

.slick-dots li{
  width: 10px;
}


.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

