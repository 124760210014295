/*
    Component Styles - Header
*/

.topbar-left{
    flex-basis: 20%;
    display: flex;
    align-items: center;
    text-align: left;
}
@media (min-width: 768px ){ .topbar-left{ flex-basis: 33.33%; }}

.topbar-center{
    flex-basis: 60%;
    align-items: center;
    text-align: center;
}
@media (min-width: 768px ){ .topbar-center{ flex-basis: 33.33%; }}

.topbar-center img{
    max-width: 100%;
    display: block;
    margin: auto;
}

.topbar-right{
    flex-basis: 20%;
    align-items: center;
    text-align: right;
}
@media (min-width: 768px ){ .topbar-right{ flex-basis: 33.33%; }}