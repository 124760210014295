/*
    Component Styles - Product Betting
*/

.product-betting-title{
    margin-top: 2rem !important;
    text-align: center;
    font-size: 20px !important;
    font-weight: 500 !important;
}

ul.betting{
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    justify-content: flex-start;
    box-sizing: border-box;
    
    max-width: 375px;
    margin: 1rem auto;
}

ul.betting li{
    font-size: 0.9375rem; /*theme.typography.pxToRem(15)*/
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

ul.betting li label{
    position: relative;
    display: flex;
    width: 48px; /* 48/56px*/
    height: 48px; /* 48/56px*/
    border: solid 2px rgba(0, 0, 0, 0.12);
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    margin: 5px;
    font-weight: 500;
    font-size: 1.4rem;
}

/*@media (min-width: 1024px ){
    ul.betting li label{
        width: 56px;
        height: 56px;
    }
}*/

ul.betting li label:hover{
    background-color: rgba(0, 0, 0, 0.12);
    cursor: pointer;
}

ul.betting li label.selected{
    background-color: rgba(0, 0, 0, 0.9);
    color: #FAFAFA; /*theme.palette.secondary.main*/
}

ul.betting li label .check{
    position: absolute;
    left: -5000px;
}

.choosepanel{
    border-bottom: 1px solid rgba(0,0,0,0.12);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
}

.buypanel{
    border-top: 1px solid rgba(0,0,0,0.12);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
}

.buypanel .left, .choosepanel .left{
    display: flex;
    flex-direction: column;
    align-items: left;
}

.buypanel .right, .choosepanel .right{
    display: flex;
    flex-direction: column;
    align-items: right;
}

.choosepanel .choosebutton, .buypanel .choosebutton{
    border-radius: 25px;
    padding: .5rem 2rem;
    pointer-events: initial;
    text-transform: uppercase;
    background: #000;
    color: #fff;
}

.choosepanel .choosebutton:hover, .buypanel .choosebutton:hover{
    background: #d4c8b7;
    color: #000;
}

.buypanel .playbutton{
    border-radius: 25px;
    padding: .5rem 2rem;
    pointer-events: initial;
    text-transform: uppercase;
    background: #01a299;
    color: #fff;
}

.buypanel .playbutton:hover{
    background: #fff;
    color: #01a299;
    border: 1px solid #01a299;
}